(function($) {
  "use strict";
  // ****GSAP*****//
  $(window).on("load",function(){  
    setTimeout(function(){
  var controller = new ScrollMagic.Controller();
  
  //e-commerce web
  var mageSale2 = TweenMax.to(".homesec2-right-desk .homesec2-right-img1", 1, {y:300});
  new ScrollMagic.Scene({
    triggerElement: $('.homesec2-right-desk')[0],
    duration: '90%',
    triggerHook: "0.9"
  })
  .setTween(mageSale2)
  .addTo(controller);
  
  
  /*
  var mageSale2 = TweenMax.to(".homesec2-right-desk .homesec2-right-img2 ", 1, {y:-150});
  new ScrollMagic.Scene({
    triggerElement: $('.homesec2-right-desk')[0],
    duration: '90%',
    triggerHook: "0.9"
  })
  .setTween(mageSale2)
  .addTo(controller);*/
  
  var mageSale2 = TweenMax.to(".homesec2-right-mob .homesec2-right-img1", 1, {y:-25});
  new ScrollMagic.Scene({
    triggerElement: $('.homesec2-right-mob')[0],
    duration: '90%',
    triggerHook: "0.9"
  })
  .setTween(mageSale2)
  .addTo(controller);
  
  var mageSale2 = TweenMax.to(".homesec2-right-mob .homesec2-right-img2 ", 1, {y:25});
  new ScrollMagic.Scene({
    triggerElement: $('.homesec2-right-mob')[0],
    duration: '90%',
    triggerHook: "0.9"
  })
  .setTween(mageSale2)
  .addTo(controller);
  
  //Web Development
  var WebDevelopment = TweenMax.to(".web-development-shadow", 1, {autoAlpha:1});
  new ScrollMagic.Scene({
    triggerElement: $('.web-development-left')[0],
    duration: '40%',
    triggerHook: "0.5",
  })
  .setTween(WebDevelopment) 
  .addTo(controller);
  
  //SOFTWARE
  var Software = TweenMax.to(".soft-moving-ele", 1, {x:-$('.software-dev h2').width()});
  new ScrollMagic.Scene({
    triggerElement: $('.software-dev h2')[0],
    duration: '100%',
    triggerHook: "0.90",
  })
  .setTween(Software)  
  .addTo(controller);
  
  //GSAP Horizontal scorll
  /*var horizontalSlide = new TimelineMax().to(".scroll-area", 1, {x: "-75%", transformStyle:"preserve-3d"})
  new ScrollMagic.Scene({
    triggerElement: ".scroll-ontainer",
    triggerHook: "0",
    duration: "300%"
  })
  .setPin(".scroll-ontainer")
  .setTween(horizontalSlide)
  .addTo(controller);*/
  
  //MOBILE APPLICATION
  var mobileApp = TweenMax.to(".mobile-app-blind", 1, {x:$('.mobile-app-right h2').width()});
  new ScrollMagic.Scene({
    triggerElement: $('.mobile-app-right h2')[0],
    duration: '100%',
   triggerHook: "0.90",
  })
  .setTween(mobileApp)
  .addTo(controller);
  
  //******SEO PAGE*****//
  var seo1ele = TweenMax.to(".seo-shape1-img", 1, {y:$('.seo-shape1').height()-70});
  new ScrollMagic.Scene({
    triggerElement: $('.seo-shape1')[0],
    duration: '50%',  
  })
  .setTween(seo1ele)  
  .addTo(controller);
  
  var seo2ele = TweenMax.to(".seo-shape2-img", 1, {y:$('.seo-shape2').height()-50});
  new ScrollMagic.Scene({
    triggerElement: $('.seo-shape2')[0],
    duration: '50%',
  })
  .setTween(seo2ele) 
  .addTo(controller);
  
  var seo3ele = TweenMax.to(".seo-shape3-img", 1, {y:$('.seo-shape3').height()/2});
  new ScrollMagic.Scene({
    triggerElement: $('.seo-shape3')[0],
    duration: '50%',
  })
  .setTween(seo3ele)  
  .addTo(controller);
  
  var seo4ele = TweenMax.to(".seo-shape4-img", 1, {y:$('.seo-shape4').height()-100});
  new ScrollMagic.Scene({
    triggerElement: $('.seo-shape4')[0],
    duration: '50%',
    triggerHook: "0.80",
  })
  .setTween(seo4ele)  
  .addTo(controller);
  
  /*
  var seo2ele = TweenMax.fromTo(".scroll-block4", 1, {autoAlpha:1}, {autoAlpha:0});
  new ScrollMagic.Scene({
    triggerElement: $('.triangle.triangle3')[0],
    triggerHook: "0.5",
    duration: '30%',
  })
  .setTween(seo2ele)
  .addIndicators()
  .addTo(controller);*/
  
  var horizontalScroll = new ScrollMagic.Controller({vertical: false});
  var seo2ele = TweenMax.fromTo(".scroll-block2", 1, {autoAlpha:0}, {autoAlpha:1});
  new ScrollMagic.Scene({
    triggerElement: $('.scroll-block2')[0],
    triggerHook: "0.6",
    duration: '30%',
  })
  .setTween(seo2ele) 
  .addTo(horizontalScroll); 
  
  var seo2ele = TweenMax.to(".moving-span", 1, {y:$('.moving-spanp').height()});
  new ScrollMagic.Scene({
    triggerElement: $('.scroll-block1')[0],
    triggerHook: "0",
    duration: '50%',
  })
  .setTween(seo2ele) 
  .addTo(horizontalScroll);
  
  
  
  var shape2opacity = TweenMax.fromTo(".seo-shape2", 1, {autoAlpha:0}, {autoAlpha:1});
  new ScrollMagic.Scene({
    triggerElement: $('.seo-shape2')[0],
    triggerHook: "0.58" 
  })
  .setTween(shape2opacity)
  .addTo(controller);
  
  //******ANDROID PAGE*****//
  var and1ele = TweenMax.to(".and-ele1-img", 1, {y:$('.and-ele1').height()-70});
  new ScrollMagic.Scene({
    triggerElement: $('.and-ele1')[0],
    duration: '50%',
    triggerHook: "0.15" 
  })
  .setTween(and1ele)  
  .addTo(controller);
  
  var and1eleimg2 = TweenMax.to(".and-ele1-img2", 1, {y:$('.and-ele1').height()});
  new ScrollMagic.Scene({
    triggerElement: $('.and-ele1')[0],
    duration: '65%',
    triggerHook: "0.15" 
  })
  .setTween(and1eleimg2) 
  .addTo(controller);
  
  var andele3img2 = TweenMax.to(".and-ele3-img2", 1, {y:$('.and-ele3').height()});
  new ScrollMagic.Scene({
    triggerElement: $('.and-ele3')[0],
    duration: '65%',
    triggerHook: "0.75" 
  })
  .setTween(andele3img2) 
  .addTo(controller);
  
  var andele3img2 = TweenMax.from(".blur-text-twin", 1, {filter: "blur(8px)"});
  new ScrollMagic.Scene({
    triggerElement: $('.and-ele3')[0],
    duration: '30%',
    triggerHook: "0.75" 
  })
  .setTween(andele3img2) 
  .addTo(controller);
  
  //******HYBRID PAGE*****//
  /*
  var hybridShapeRb = TweenMax.to(".hybrid-shape-rb", 1, {y:$('.hybd-ele2').height()/2});
  new ScrollMagic.Scene({
    triggerElement: $('.hybd-ele2')[0],
    duration: '100%',
  })
  .setTween(hybridShapeRb)
  .addTo(controller);*/
  
  /*
  var hbdrBlueBlock = TweenMax.to(".hbdr-blue-block", 1, {x:$('.hbdr-blue').width()});
  new ScrollMagic.Scene({
    triggerElement: $('.hbdr-blue')[0],
    duration: '100%',
  })
  .setTween(hbdrBlueBlock)
  .addTo(controller);*/
  
  //******IONIC PAGE*****//
  var ionicredplus = TweenMax.to(".ionic-red-plus", 1, {x:-$('.ionic-red-plus-block').width()/3});
  new ScrollMagic.Scene({
    triggerElement: $('.ionic-red-plus-block')[0],
    duration: '50%',
    triggerHook: "0.15" 
  })
  .setTween(ionicredplus)
  .addTo(controller);
  
  var ionicPlusShape = TweenMax.to(".ionic-plus-shape", 1, {y:-$('.ionic-plus-parent').height()+200});
  new ScrollMagic.Scene({
    triggerElement: $('.ionic-plus-parent')[0],
    duration: '100%',
  })
  .setTween(ionicPlusShape)
  .addTo(controller);
  
  var ionicAquamarine = TweenMax.to(".ionic-aquamarine", 1, {y:$('.ionic-aquamarine-parent').height()});
  new ScrollMagic.Scene({
    triggerElement: $('.ionic-aquamarine-parent')[0],
    duration: '100%',
  })
  .setTween(ionicAquamarine)
  .addTo(controller);
  
  //******IOS PAGE*****//
  var iosShapeRb = TweenMax.to(".ios-shape-rb", 1, {x:-$('.ios-top').width()/3});
  new ScrollMagic.Scene({
    triggerElement: $('ios-top')[0],
    duration: '50%',
    triggerHook: "0.15" 
  })
  .setTween(iosShapeRb)
  .addTo(controller);
  
  var iosSolution = TweenMax.to(".ios-solution", 1, {x:$('.hybd-ele2').width()});
  new ScrollMagic.Scene({
    triggerElement: $('.hybd-ele2')[0],
    duration: '100%',
  })
  .setTween(iosSolution)
  .addTo(controller);
  
  var iosSoluBlock = TweenMax.to(".ios-solu-block", 1, {y:-$('.hybd-ele2').height()+300});
  new ScrollMagic.Scene({
    triggerElement: $('.hybd-ele2')[0],
    duration: '100%',
  })
  .setTween(iosSoluBlock)
  .addTo(controller);
  
  var iosAquamarineBlock = TweenMax.to(".ios-aquamarine-block", 1, {y:-$('.ios-aquamarine-block-parent').height()-100});
  new ScrollMagic.Scene({
    triggerElement: $('.ios-aquamarine-block-parent')[0],
    duration: '100%',
  })
  .setTween(iosAquamarineBlock)
  .addTo(controller);
  
  //******MAGENTO PAGE*****//
  var magelogo2 = TweenMax.to(".mage-logo2", 1, {y:"15%"});
  new ScrollMagic.Scene({
    triggerElement: $('.magento-logos')[0],
    duration: '100%',
    triggerHook: "0.75"
  })
  .setTween(magelogo2)
  .addTo(controller);
  
  var magelogo3 = TweenMax.to(".mage-logo3", 1, {y:"43%"});
  new ScrollMagic.Scene({
    triggerElement: $('.magento-logos')[0],
    duration: '100%',
    triggerHook: "0.75"
  })
  .setTween(magelogo3)
  .addTo(controller);
  
  var magelogo4 = TweenMax.to(".mage-logo4", 1, {y:"60%"});
  new ScrollMagic.Scene({
    triggerElement: $('.magento-logos')[0],
    duration: '100%',
    triggerHook: "0.75"
  })
  .setTween(magelogo4)
  .addTo(controller);
  
  
  /**** MAGENTO PAYMENT ****/
  
  var magePay2 = TweenMax.to(".mage-pay2", 1, {y:"100%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-payment')[0],
    duration: '80%',
    triggerHook: "0.4"
  })
  .setTween(magePay2)
  .addTo(controller);
  
  var magePay3 = TweenMax.to(".mage-pay3", 1, {y:"50%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-payment')[0],
    duration: '80%',
    triggerHook: "0.4"
  })
  .setTween(magePay3)
  .addTo(controller);
  
  var magePay4 = TweenMax.to(".mage-pay4", 1, {y:"40%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-payment')[0],
    duration: '80%',
    triggerHook: "0.4"
  })
  .setTween(magePay4)
  .addTo(controller);
  
  var magePay5 = TweenMax.to(".mage-pay5", 1, {y:"-56%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-payment')[0],
    duration: '80%',
    triggerHook: "0.4"
  })
  .setTween(magePay5)
  .addTo(controller);
  
  var magePay6 = TweenMax.to(".mage-pay6", 1, {y:"-12%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-payment')[0],
    duration: '80%',
    triggerHook: "0.4"
  })
  .setTween(magePay6)
  .addTo(controller);
  
  var magePay7 = TweenMax.to(".mage-pay7", 1, {y:"20%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-payment')[0],
    duration: '80%',
    triggerHook: "0.4"
  })
  .setTween(magePay7)
  .addTo(controller);
  
  var magePay8 = TweenMax.to(".mage-pay8", 1, {y:"-40%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-payment')[0],
    duration: '80%',
    triggerHook: "0.4"
  })
  .setTween(magePay8)
  .addTo(controller);
  
  
  var mageSale1 = TweenMax.to(".mage-sale1", 1, {y:"38%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-sale')[0],
    duration: '150%',
    triggerHook: "onEnter"
  })
  .setTween(mageSale1)
  .addTo(controller);
  
  var mageSale2 = TweenMax.to(".mage-sale2", 1, {y:"-48%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-sale')[0],
    duration: '150%',
    triggerHook: "onEnter"
  })
  .setTween(mageSale2)
  .addTo(controller);
  
  /*var mageSale3 = TweenMax.to(".mage-sale3", 1, {y:"0%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-sale')[0],
    duration: '150%',
    triggerHook: "onEnter"
  })
  .setTween(mageSale3)
  .addTo(controller);*/
  
  var mageSale4 = TweenMax.to(".mage-sale4", 1, {x:"15%", y:"-15%"});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-sale')[0],
    duration: '150%',
    triggerHook: "onEnter"
  })
  .setTween(mageSale4)
  .addTo(controller);
  
  /****** MAGENO ICON *****/
  var mageBdr1 = TweenMax.to(".mage-ico-bdr1", 1, {x:-5, y:7.5});
  new ScrollMagic.Scene({
    triggerElement: $('.magento-top-icon')[0],
    duration: '40%',
    triggerHook: "0.2"
  })
  .setTween(mageBdr1)
  .addTo(controller);
  
  
  var mageBdr2 = TweenMax.to(".mage-ico-bdr2", 1, {x:-10, y:15});
  new ScrollMagic.Scene({
    triggerElement: $('.magento-top-icon')[0],
    duration: '40%',
    triggerHook: "0.2"
  })
  .setTween(mageBdr2)
  .addTo(controller);
  
  var mageBdr3 = TweenMax.to(".mage-ico-bdr3", 1, {x:-15, y:22.5});
  new ScrollMagic.Scene({
    triggerElement: $('.magento-top-icon')[0],
    duration: '40%',
    triggerHook: "0.2"
  })
  .setTween(mageBdr3)
  .addTo(controller);
  
  var mageSale4 = TweenMax.to(".mage-ico-bdr4", 1, {x:-20, y:30});
  new ScrollMagic.Scene({
    triggerElement: $('.magento-top-icon')[0],
    duration: '40%',
    triggerHook: "0.2"
  })
  .setTween(mageSale4)
  .addTo(controller);
  
  var mageSale4 = TweenMax.fromTo(".mage-move-tit", 1, {y:-20}, {y:15});
  new ScrollMagic.Scene({
    triggerElement: $('.mage-move-tit')[0],
    duration: '100%',
    triggerHook: "onEnter"
  })
  .setTween(mageSale4)
  .addTo(controller);
  
  /***** PHP PAGE *****/
  var phpGrBlock1 = TweenMax.to(".php-gr-block1", 1, {y:"50%"});
  new ScrollMagic.Scene({
    triggerElement: $('.php-grblocks-col')[0],
    duration: '70%',
    triggerHook: "0.15"
  })
  .setTween(phpGrBlock1)
  .addTo(controller);
  
  var phpGrBlock2 = TweenMax.to(".php-gr-block2", 1, {y:"-50%"});
  new ScrollMagic.Scene({
    triggerElement: $('.php-grblocks-col')[0],
    duration: '70%',
    triggerHook: "0.15"
  })
  .setTween(phpGrBlock2)
  .addTo(controller);
  
  
  /***** Amazon Account Management PAGE *****/
  
  var ammWarning2 = TweenMax.to(".amm-warning2", 1, {x:"-3%", y:"-10%"});
  new ScrollMagic.Scene({
    triggerElement: $('.amm-warning')[0],
    duration: '80%',
    triggerHook: "0.9"
  })
  .setTween(ammWarning2)
  .addTo(controller);
  
  var ammWarning3 = TweenMax.to(".amm-warning3", 1, {x:"-6%", y:"-20%"});
  new ScrollMagic.Scene({
    triggerElement: $('.amm-warning')[0],
    duration: '80%',
    triggerHook: "0.9"
  })
  .setTween(ammWarning3)
  .addTo(controller);
  
  var ammWarning4 = TweenMax.to(".amm-warning4", 1, {x:"-9%", y:"-30%"});
  new ScrollMagic.Scene({
    triggerElement: $('.amm-warning')[0],
    duration: '80%',
    triggerHook: "0.9"
  })
  .setTween(ammWarning4)
  .addTo(controller);
  
  var ammWarning5 = TweenMax.to(".amm-warning5", 1, {x:"-12%", y:"-40%"});
  new ScrollMagic.Scene({
    triggerElement: $('.amm-warning')[0],
    duration: '80%',
    triggerHook: "0.9"
  })
  .setTween(ammWarning5)
  .addTo(controller);
  
  
  var ammWarning4 = TweenMax.to(".amm-dolors1", 1, {y:"-15%"});
  new ScrollMagic.Scene({
    triggerElement: $('.amm-dolors')[0],
    duration: '80%',
    triggerHook: "0.6"
  })
  .setTween(ammWarning4)
  .addTo(controller);
  
  var ammWarning5 = TweenMax.to(".amm-dolors3", 1, {y:"15%"});
  new ScrollMagic.Scene({
    triggerElement: $('.amm-dolors')[0],
    duration: '80%',
    triggerHook: "0.6"
  })
  .setTween(ammWarning5)
  .addTo(controller);
  
  /***** PORTFOLIO PAGE ****/
  var portfoliocube1 = TweenMax.to(".portfolio-cube1", 1, {y:"50%", rotation:320});
  new ScrollMagic.Scene({
    triggerElement: $('.portfolio-squres')[0],
    duration: '100%',
    triggerHook: "0.7"
  })
  .setTween(portfoliocube1)
  .addTo(controller);
  
  var portfoliocube1 = TweenMax.to(".portfolio-cube2", 1, {y:"50%", rotation:320});
  new ScrollMagic.Scene({
    triggerElement: $('.portfolio-squres')[0],
    duration: '100%',
    triggerHook: "0.7"
  })
  .setTween(portfoliocube1)
  .addTo(controller);
  
  var portfoliocube1 = TweenMax.to(".portfolio-cube3", 1, {y:"50%", rotation:320});
  new ScrollMagic.Scene({
    triggerElement: $('.portfolio-squres')[0],
    duration: '100%',
    triggerHook: "0.7"
  })
  .setTween(portfoliocube1)
  .addTo(controller);
  
  var portfoliocube1 = TweenMax.to(".portfolio-cube4", 1, {y:"50%", rotation:320});
  new ScrollMagic.Scene({
    triggerElement: $('.portfolio-squres')[0],
    duration: '100%',
    triggerHook: "0.7"
  })
  .setTween(portfoliocube1)
  .addTo(controller);
  
  var portfoliocube1 = TweenMax.to(".portfolio-cube5", 1, {y:"50%", rotation:-320});
  new ScrollMagic.Scene({
    triggerElement: $('.portfolio-squres')[0],
    duration: '100%',
    triggerHook: "0.7"
  })
  .setTween(portfoliocube1)
  .addTo(controller);
  
  
  $("h2, h3").each(function(i) {
    let thisLine = $(this);
    var action = gsap.timeline()
    .from(thisLine,{opacity: 0, x: 50}, {opacity: 1, x: 0, transformStyle:"preserve-3d"})    
    new ScrollMagic.Scene({
      triggerElement: this,
      duration: 0,
      triggerHook: 0.8
    })
      .setTween(action)
      .addTo(controller)
  });
  
  $("p:not(footer p)").each(function(i) {
    let thisLine = $(this);
    var action = gsap.timeline()
    .from(thisLine,{opacity: 0, y: 50}, {opacity: 1, y: 0, transformStyle:"preserve-3d"})    
    new ScrollMagic.Scene({
      triggerElement: this,
      duration: 0,
      triggerHook: 0.8
    })
      .setTween(action)
      .addTo(controller)
  });
  
  }, 500);
  });
  })(jQuery);