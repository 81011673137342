/*******Start Preloader********/
function Ticker( elem ) {
	elem.lettering();
	this.done = false;
	this.cycleCount = 5;
	this.cycleCurrent = 0;
	this.chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()-_=+{}|[]\\;\':"<>?,./`~'.split('');
	this.charsCount = this.chars.length;
	this.letters = elem.find( 'span' );
	this.letterCount = this.letters.length;
	this.letterCurrent = 0;

	this.letters.each( function() {
		var $this = $( this );
		$this.attr( 'data-orig', $this.text() );
		$this.text( '-' );
	});
}

Ticker.prototype.getChar = function() {
	return this.chars[ Math.floor( Math.random() * this.charsCount ) ];
};

Ticker.prototype.reset = function() {
	this.done = false;
	this.cycleCurrent = 0;
	this.letterCurrent = 0;
	this.letters.each( function() {
		var $this = $( this );
		$this.text( $this.attr( 'data-orig' ) );
		$this.removeClass( 'done' );
	});
	this.loop();
};

Ticker.prototype.loop = function() {
	var self = this;

	this.letters.each( function( index, elem ) {
		var $elem = $( elem );
		if( index >= self.letterCurrent ) {
			if( $elem.text() !== ' ' ) {
				$elem.text( self.getChar() );
				$elem.css( 'opacity', Math.random() );
			}
		}
	});

	if( this.cycleCurrent < this.cycleCount ) {
		this.cycleCurrent++;
	} else if( this.letterCurrent < this.letterCount ) {
		var currLetter = this.letters.eq( this.letterCurrent );
		this.cycleCurrent = 0;
		currLetter.text( currLetter.attr( 'data-orig' ) ).css( 'opacity', 1 ).addClass( 'done' );
		this.letterCurrent++;
	} else {
		this.done = true;
	}

	if( !this.done ) {
		requestAnimationFrame( function() {
			self.loop();
		});
	} else {
		setTimeout( function() {
			self.reset();
		}, 750 );
	}
};

$words = $('.loader-word' );

$words.each( function() {
	var $this = $( this ),
		ticker = new Ticker( $this ).reset();
	$this.data( 'ticker', ticker  );
});
/*******End Preloader********/

/********Window On load******/
$(window).on("load",function(){
  // Portfolio Filter
  setTimeout(function(){    
    if (window.innerHeight < 730){ 
      $(".tool-sec-tool").insertAfter(".first-screen");
    } else{
      $(".tool-sec-tool").insertAfter(".tool-sec2");
    };

      //Track page dropdown
    $("#country").chosen({no_results_text: "Oops, nothing found!"});
    
  }, 500); 

  //Script For SVG CODE
  jQuery("img.svg").each(function(){
    var $img = jQuery(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    jQuery.get(
      imgURL,
      function(data){
        var $svg = jQuery(data).find("svg");
        if (typeof imgID !== "undefined"){
          $svg = $svg.attr("id", imgID);
        }
        if (typeof imgClass !== "undefined"){
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }
        $svg = $svg.removeAttr("xmlns:a");  
        if (!$svg.attr("viewBox") &&
            $svg.attr("height") &&
            $svg.attr("width")
        ){
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }
        $img.replaceWith($svg);
      },
      "xml"
    );
  });
  jQuery('.screen').scrollImage();
  
  var mousehover = false;
  $(".hpost-title").on("mouseenter", function(){
    mousehover = true;
  });
  $(".hpost-title").on("mouseleave", function(){
    mousehover = false;
  });
  function setRandomClass(){    
    var ul = $(".hpost-titles");
    var items = ul.find(".hpost-title");
    var number = items.length;
    var random = Math.floor((Math.random() * number));
    if (mousehover == false){
      items.removeClass("hpost-title-show");      
      setTimeout(function(){
        items.eq(random).addClass("hpost-title-show");
      }, 80);
    }
  };
  /*setInterval(function (){
    setRandomClass();
  }, 16000);  */
  var startedAt, duration = 16000;
  function start() {
    startedAt = Date.now()
    setRandomClass(0)
    requestAnimationFrame(update)
  }
  function update() {
    let elapsedTime = Date.now() - startedAt
    let playback = elapsedTime / duration
    setRandomClass(playback)  
    if (playback > 0 && playback < 1) {
      // Queue the next frame
      requestAnimationFrame(update)
    } else {
      // Wait for a while and restart the animation
      setTimeout(start, duration)
    }
  }
  start(); 

  setTimeout(function(){  
    var voicelist = responsiveVoice.getVoices();
    var vselect = $("#voiceselection");
    /***$.each(voicelist, function(){
      vselect.append($("<option />").val(this.name).text(this.name));
    });***/
    /*for(var i =0;i<9;i++){
      if(i!=4 && i!=5 && i!=6){       
        vselect.append($("<option />").val(voicelist[i].name).text(voicelist[i].name));
      }
    }*/

    // var textSpeech = $('#textSpeech').text();
    var textSpeech =  document.getElementById('textSpeech').innerText;
    var voiceselection = document.getElementById('voiceselection').value;
    $("#voiceselection").on('change', function(){    
      voiceselection = this.value; 
    });
    
    $('#play').on( "click", function(e){
      responsiveVoice.speak(textSpeech, voiceselection);
    });
    $('#pause').on( "click", function(e){
      responsiveVoice.pause(textSpeech, voiceselection);
    });
    $('#resume').on( "click", function(e){
      responsiveVoice.resume(textSpeech, voiceselection);
    });
    $('#stop').on( "click", function(e){
      responsiveVoice.cancel(textSpeech, voiceselection);
    });     
    $(".sound-button").on( "click", function(e){
      $(".sound-button").removeClass("active");
      $(this).addClass("active");
    });
  }, 500); 

  function portfolio_init(){
    var portfolio_grid = $('#portfolio_grid'), portfolio_filter = $('#portfolio_filters');
    if (portfolio_grid){
      portfolio_grid.shuffle({
        speed: 450,
        itemSelector: '.port-item'
      });         
      portfolio_filter.on("click", ".filter", function (e){
        portfolio_grid.shuffle('update');
        e.preventDefault();
        $('#portfolio_filters .filter').parent().removeClass('active');
        $(this).parent().addClass('active');
        portfolio_grid.shuffle('shuffle', $(this).attr('data-group') );
      });
    }
  }
  setTimeout(() => {
    portfolio_init();
  }, 200);

  if ($("body").hasClass("page-template-portfolio")) {
    var config = {
      totalParticles: 10000,
      particleSize: 1,
      particleSpeed: 0.1,
      near: 0.1,
      far: 10000,
      x_rot: 210,
      y_rot: 135,
      z_pos: -263
    }
  
    var width = window.innerWidth;
    var height = window.innerHeight;
    var renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
  
    document.getElementById("portfolioBg").appendChild(renderer.domElement);
  
    var scene = new THREE.Scene;
    var camera = new THREE.PerspectiveCamera(45, width / height, config.near, config.far);
    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = config.z_pos;
    scene.add(camera);
    sSize = 400;
  
    var particles = new THREE.Geometry;
    var particleMaterial = new THREE.ParticleBasicMaterial({ color: '#ffffff' });
    var min = 0 - (sSize / 2);
    var px = [];
  
    for (var p = 0; p < config.totalParticles; p++) {
      var particle = new THREE.Vector3(
        Math.floor(Math.random() * sSize) + min,
        Math.floor(Math.random() * sSize) + min,
        Math.floor(Math.random() * sSize) + min
      );
      var temp = {
        angle: Math.floor(Math.random() * 360) + 1,
        speed: config.particleSpeed
      }
      px.push(temp);
      particles.vertices.push(particle);
    }
  
    var particleSystem = new THREE.ParticleSystem(particles, particleMaterial);
    scene.add(particleSystem);
    var pointLight = new THREE.PointLight(0xffffff);
    pointLight.position.set(0, 300, 200);
    scene.add(pointLight);
  
    function render() {
      requestAnimationFrame(render);
      function collide(x, y, angle) {
        if (x > (sSize / 2) || x < (0 - (sSize / 2))) {
          angle = 180 - angle;
        } else if (y > sSize / 2 || y < (0 - (sSize / 2))) {
          angle = 360 - angle;
        }
        return angle;
      }
  
      function update(angle, speed, x, y) {
        radians = angle * Math.PI / 180;
        ret = {
          x: x += Math.cos(radians) * speed,
          y: y += Math.sin(radians) * speed
        };
        return ret;
      }
      for (var i = 0; i < particleSystem.geometry.vertices.length; i++) {
        var p = particleSystem.geometry.vertices[i];
        px[i].angle = collide(p.x, p.y, px[i].angle);
        var t = update(px[i].angle, px[i].speed, p.x, p.y);
        particleSystem.geometry.vertices[i].set(t.x, t.y, p.z);
        particleSystem.rotation.x = config.x_rot;
        particleSystem.rotation.y = config.y_rot;
        particleSystem.position.z = config.z_pos;
        particleSystem.geometry.verticesNeedUpdate = true;
      }
      renderer.render(scene, camera);
    }
    render();
  
    window.addEventListener('resize', function(){
      var width = window.innerWidth;
      var height = window.innerHeight;
      renderer.setSize( width, height );
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    });
  }
  
  if ($("body").hasClass("page-template-home")) {
    var renderer, scene, camera;
    var sapphire, uniforms;
    var displacement, noise;
    const TEXTURE_PATH = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/1037366';
    init();
    animate();
    function init() {
      camera = new THREE.PerspectiveCamera(30, $(".home-ani-par").width() / $(".home-ani-par").width(), 1, 10000);
      camera.position.z = 570;
      scene = new THREE.Scene();
      loader = new THREE.TextureLoader();
      loader.setCrossOrigin('https://s.codepen.io');
      uniforms = {
        amplitude: { value: 1 },
        color: { value: new THREE.Color(0x00d8ff) },
        texture: { value: loader.load('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1037366/textture.jpg') }
      };
      uniforms.texture.value.wrapS = uniforms.texture.value.wrapT = THREE.RepeatWrapping;
      var shaderMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: document.getElementById('vertexshader').textContent,
        fragmentShader: document.getElementById('fragmentshader').textContent
      });
  
      var geometry = new THREE.TorusBufferGeometry(1, 10, 400, 4);
      displacement = new Float32Array(geometry.attributes.position.count);
      noise = new Float32Array(geometry.attributes.position.count);
      for (var i = 0; i < displacement.length; i++) {
        noise[i] = Math.random() * 10;
      }
      geometry.addAttribute('displacement', new THREE.BufferAttribute(displacement, 1));
      sapphire = new THREE.Mesh(geometry, shaderMaterial);
      sapphire.scale.x = 0.07;
      sapphire.scale.y = 0.11;
      sapphire.scale.z = 0.07;
      sapphire.position.y = 4.5;
      scene.add(sapphire);
      renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setClearColor(0x000000, 0);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize($(".home-ani-par").width(), $(".home-ani-par").width());
      camera.lookAt(sapphire.position);
  
      var container = document.getElementById('sapphire');
      container.appendChild(renderer.domElement);
      window.addEventListener('resize', onWindowResize, false);
    }
    function onWindowResize() {
      camera.aspect = $(".home-ani-par").width() / $(".home-ani-par").height();
      camera.updateProjectionMatrix();
      renderer.setSize($(".home-ani-par").width(), $(".home-ani-par").height());
    }
    function animate() {
      requestAnimationFrame(animate);
      render();
    }
    function render() {
      var time = Date.now() * 0.002;
      sapphire.rotation.y = sapphire.rotation.y = 0.2 * time;
      uniforms.amplitude.value = 3 * Math.sin(sapphire.rotation.y * 0.15);
      //uniforms.color.value.offsetHSL( 0.00001, 0.5, 0 );
      for (var i = 0; i < displacement.length; i++) {
        displacement[i] = Math.sin(0.15 * i + time);
        noise[i] += 2 * (2 - Math.random());
        noise[i] = THREE.Math.clamp(noise[i], -15, 15);
        displacement[i] += noise[i];
      }
      sapphire.geometry.attributes.displacement.needsUpdate = true;
      renderer.render(scene, camera);
    }
  }

});

/********Document Ready******/
$(function(){
  $("#preloader").remove(); 
  /***** Menu *****/
  $('.navbar-collapse').on('show.bs.collapse', function(){
    $('header').addClass('menuopen');
    $('body').addClass('menuopen');
  });
  $('.navbar-collapse').on('hidden.bs.collapse', function(){
    $('header').removeClass('menuopen');
    $('body').removeClass('menuopen')
  });

  $('.navbar-nav > li.menu-item-has-children > a').append('<span class="caret" data-toggle="dropdown"></span>');
  $('.navbar-nav > li.menu-item-has-children').addClass('dropdown');  
  $(".navbar-nav > li.menu-item-has-children > a").attr("data-toggle", "dropdown");      
  
  $('.dropdown-menu a.dropdown-toggle').on('click', function(e){  
    if (!$(this).next().hasClass('show')){
    $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");  
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');
      
    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e){
    $('.dropdown-submenu .show').removeClass("show");
    });  
    return false;
  });

  //Menu Contact US Link
  $('.contact-link').on( "click", function(e){
    e.preventDefault();
    $('.navbar-collapse').collapse('hide');
    setTimeout(function (){
      window.location.href = "#contact-us";       
      $("html, body").animate({scrollTop: $("#contact-us").offset().top - 50}, 0);
    },500);  
  });
  //End Menu 

  // Chat box open
  $('.w-btn').on('click', function(e){   
    var $subMenu = $(".w-box");
    $subMenu.toggleClass('show');   
  });

  //Typed JS
  Typed.new('#typed',{
    stringsElement:document.getElementById('typed-strings'),
    typeSpeed:70,
    loop:true,
    loopCount:Infinity
  }); 

  Typed.new('#typed1',{
    stringsElement:document.getElementById('typed-strings1'),
    typeSpeed:70,
    loop:true,
    loopCount:Infinity,
  }); 

  Typed.new('#typedStudio',{
    stringsElement:document.getElementById('typedStudio-strings'),
    typeSpeed:70,
    loop:false
  }); 
  
  Typed.new('#typedCyber',{
    stringsElement:document.getElementById('typedCyber-strings'),
    typeSpeed:70,
    loop:false
  }); 

  //Slick Slider
  $('.home-portfolio').owlCarousel({
    loop:true,  
    autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 4
      },
      1024: {
        items: 5
      }
    }
  });

  $('.android-portfolio').owlCarousel({
    loop:true,  
    autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      860: {
        items: 3
      },
      1024: {
        items: 4
      }
    }
  });
  
  function handleMarquee() {
    const marquee = document.querySelectorAll('.marquee-container');
    let speed = 2;
    marquee.forEach(function (el) {
      // stop animation on mouseenter
      mouseEntered1 = false;
      document.querySelector('.marquee').addEventListener('mouseenter', function () {mouseEntered1 = true;})
      document.querySelector('.marquee').addEventListener('mouseleave', function () {mouseEntered1 = false})
      const container = el.querySelector('.marquee');
      const content = el.querySelector('.marquee > *');
      //Get total width
      const elWidth = content.offsetWidth;
      //Duplicate content
      let clone = content.cloneNode(true);
      container.appendChild(clone);
      let progress = 2;
      function loop() {
        if (mouseEntered1 === false) { progress = progress - speed; }
        if (progress <= elWidth * -1) { progress = 0; }
        container.style.transform = 'translateX(' + progress + 'px)';
        window.requestAnimationFrame(loop);
      }
      loop();
    });    
  };
  handleMarquee(); 

  //Track page Ajex form
  $("#toolForm").on("submit", function(){
    e.preventDefault();
    var barCode = $("#barCode").val();
    var country = $("#country").val();
    if(barCode == "" || country == "" ){
      $("#error_message").show().html("All Fields are Required");
    } else{
      var spinner = $('#loader');
      spinner.show(); 
      $("#error_message").html("").hide();        
        $.ajax({
          type: "GET",
          url: "https://sellerboosta.com/rest/navines/tracking?trackingno="+barCode+"&countrycode="+country,    
          success: function(json){   
            var showData = $('#show-data'); 
            var showTitle = $('#data-title');  
            var showNoData = $('#no-data'); 
            var downloadIcon = $('#exportPdf')    
            var copyBtn = $('.copytext');                     
            showData.empty();
            showTitle.empty();
            spinner.hide();
            showNoData.empty();
            if(!json.TrackingDetails){
              spinner.hide();       
              $('#toolModal').modal('show');  
              downloadIcon.hide();  
              copyBtn.hide();  
              showNoData.html("Tracking ID not found.");
            }    
            if(json.TrackingDetails.length){ 
              spinner.hide();  
              downloadIcon.show(); 
              copyBtn.show();        
              $('#toolModal').modal('show');                  
              var isTital = true;
              json.TrackingDetails.map(function (item){
                if (isTital){
                    var text = item.trackingNumber;                  
                    var title = $('<span />').html(text);
                    showTitle.append(title); 
                    isTital = false;
                }
                var content = ''
                content = content + '<div class="col-lg-6 modaltool-col"> <h5>Date</h5> <p>' + item.datetime + '</p></div>'
                content = content + '<div class="col-lg-6 modaltool-col"> <h5>Carrier</h5> <p>' + item.post + '</p></div>'
                content = content + '<div class="col-lg-6 modaltool-col"> <h5>Event/location</h5> <p>' + item.eventLocation + '</p></div>'
                content = content + '<div class="col-lg-6 modaltool-col"> <h5>Additional</h5> <p>' + item.additional + '</p></div>'; 
                            
                copyStr = copyStr + 'Date & Time: ' + item.datetime + '\n';
                copyStr = copyStr + 'Carrier: ' + item.post + '\n';
                copyStr = copyStr + 'Event/Location: ' + item.eventLocation + '\n';
                if (item.additional.length > 0){
                  copyStr = copyStr + 'Additional: ' + item.additional + '\n';
                } else{
                  copyStr = copyStr + 'Additional: -\n';
                }
                copyStr = copyStr + '---------------------------------------\n';

                var list = $(' <div class="row modaltool-data" />').html(content);               
                showData.append(list);                                      
              });          
            }
            /*** if(json.errrorMsg){
              $("#show-data").show().html("Tracking ID not found.");
            } ***/ 
            // $("form").trigger("reset");  
            $('#toolModal').modal('show');
          }          
        });
      }
     
  });

  // Track page Export PDF
  $("#exportPdf").on("click", function(event){  
    var barCode = $("#barCode").val();
    var country = $("#country").val();           
      window.open("https://sellerboosta.com/rest/navines/trackingdetailspdf?trackingno="+barCode+"&countrycode="+country, "_self");
  });

  // Track pageCopy Popup Data
  function copyToClipboard(element){
    var $temp = $("<textarea>");
    $("body").append($temp);
    $temp.val(copyStr).select();
    document.execCommand("copy");
    $temp.remove();     
  }
  $('#copyBtn').on('click',function(){    
    var $pdfData = $("#pdfData");
    copyToClipboard(pdfData);
  }); 
  var copyStr = '';

  // Contact Form Validation
  window.addEventListener('load', function(){
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function(form){
      form.addEventListener('submit', function(event){
        if (form.checkValidity() === false){
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);

  //*****Ajex Contact form ******/
  $(function(){
    $('#frmDemo').ebcaptcha();
    $('#contactFrm').frmcaptcha();
  });
  (function($){
    var randomNr1 = 0; 
    var randomNr2 = 0;
    var totalNr = 0;   

    randomNr1 = Math.floor(Math.random()*10);
    randomNr2 = Math.floor(Math.random()*10);
    totalNr = randomNr1 + randomNr2; 
    jQuery.fn.ebcaptcha = function(options){
      var input = $('#ebcaptchainput'); 
      var label = $('#ebcaptchatext'); 
      var validMsg = $('#captchaAlert'); 
      var texti = "What is "+randomNr1+" + "+randomNr2;
      $(label).text(texti);    
      $(input).keyup(function(){
        var nr = $("#ebcaptchainput").val();  
        if(nr==totalNr)
       {
          validMsg.hide();				
          $(input).removeClass('no-valid');
          $(input).addClass('valid');
        }
        else{
          validMsg.show();
          $(input).removeClass('valid');
          $(input).addClass('no-valid');        
        }			
      });   
    };

    var totalNr1 = 0; 
    var randomNr3 = 0; 
    var randomNr4 = 0;
    randomNr3 = Math.floor(Math.random()*10);
    randomNr4 = Math.floor(Math.random()*10);
    totalNr1 = randomNr3 + randomNr4;
    console.log(totalNr1);

    jQuery.fn.frmcaptcha = function(e){
      var input1 = $('#captchaInput'); 
      var label1 = $('#conCaptchaText'); 
      var validMsg1 = $('#contCaptchaAlert'); 
      var texti1 = "What is "+randomNr3+" + "+randomNr4;
      console.log(texti1);
      $(label1).text(texti1);
    
      $(input1).keyup(function(){
        var nr1 = $("#captchaInput").val();  
        if(nr1==totalNr1)
       {
          validMsg1.hide();				
          $(input1).removeClass('no-valid');
          $(input1).addClass('valid');
        }
        else{
          validMsg1.show();
          $(input1).removeClass('valid');
          $(input1).addClass('no-valid');        
        }			
      });   
    };

    $("#frmDemo").on("submit", function(e){
      e.preventDefault();   
      var name = $("#name").val();
      var email = $("#email").val();
      var msg = $("#message").val();
      var nr = $("#ebcaptchainput").val(); 
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(name == "" || email == "" || msg == "" || nr == "" || nr != totalNr){
        $("#error_message").show().html("All Fields are Required");  
      } else if(email.match(mailformat)){
        $("#error_message").html("").hide(); 
          $.ajax({
            type: "POST",
            url: "https://www.navines.com/wp-content/themes/navines/form2.php",
            data: "name="+name+"&email="+email+"&message="+msg,
            success: function(data){ 
              $('#myModal').modal('show');
            }
          });
        }else{
          $("#error_message").show().html("All Fields are Required");
        }
    });

    $("#formStart").on("click", function(){
      $("#frmAnalyze").show();
      $(this).hide();
    });

    $("#frmAnalyze").on("submit", function(e){
      e.preventDefault();   
      var websiteurl = $("#websiteurl").val(); 
      var anaEmail = $("#anaEmail").val();
      var urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-z]+$/;
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(websiteurl == ""){
        $(".validate-analyze").show().html("Please enter Valid URL.");  
      } else if(urlPattern.test(websiteurl)){
        $(".validate-analyze").html("").hide(); 
        $(".ana-emailgroup").show();
        if(!anaEmail.match(mailformat)){
          $(".validate-email").show().html("Please enter Valid Email.");
        } else {
          $(".validate-email").html("").hide();
          $.ajax({
            type: "POST",
            url: "https://www.navines.com/wp-content/themes/navines/analyze.php",
            data: "websiteurl="+websiteurl+"&anaEmail="+anaEmail,
            success: function(data){
              $(".btn-analyze").addClass("analyze-clicked");             
              setTimeout(function(){
                $(".btn-analyze").removeClass("analyze-clicked");            
              }, 3500);
              $(".analyze-success-message").show().html("we will analyze and get back to you soon.");
            }
          });
        }
      }else{
        $(".validate-analyze").show().html("Please enter Valid URL.");      
      }
    });

    $("#contactFrm").on("submit", function(e){
      e.preventDefault();   
      var name = $("#conName").val();
      var email = $("#conEmail").val();
      var phone = $("#conPhone").val();
      var service = $("#conService").val();
      var msg = $("#conMessage").val();
      var nr1 = $("#conCaptcha").val(); 
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;	    
      if(name == "" || email == "" || phone == "" || service == "" || msg == "" || nr1 == "" || nr1 != totalNr1){
        $("#con_message").show().html("All Fields are Required");  
      } else if(email.match(mailformat)){
        $("#con_message").html("").hide();
        $.ajax({
          type: "POST",
          url: "https://www.navines.com/wp-content/themes/navines/contact-mail.php",
          data: "name="+name+"&email="+email+"&phone="+phone+"&service="+service+"&message="+msg,
          success: function(data){ 
            $('#myModal').modal('show');
          }
        });
      }else{
        $("#con_message").show().html("All Fields are Required");
      }
    });

    $(".label-start-btn").on("click", function(){
      $("#labelForm").show();
      $(this).hide();
    });
        

    $("#labelForm").on("submit", function(e){
      e.preventDefault();
      var budgetquote = $("#budgetquote").val();
      if(budgetquote == "" ){
        $("#error_message").show();  
      } else{
        $("#error_message").html("").hide(); 
        $.ajax({
          type: "POST",
          url: "https://www.navines.com/wp-content/themes/navines/label-form.php",
          data: "budgetquote="+budgetquote,
          success: function(data){}
        });
      }
    });
  })(jQuery);
  //*****Ajex Contact form End******//

  // Window Inner Height
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);   

  /***** Switch Theme *****/
  $(".switch-cursor").on("click", function(){
    $("body").toggleClass("default-cursor");
  });
  $(".switch-color").on("click", function(){
    $("body").toggleClass("white-mode");
  });

  var switchColor = localStorage.getItem('switchColor');
  checkSwitch();
  function checkSwitch(){
    if(switchColor == "switchOn"){
      $('body').addClass("white-mode"); 
      $(".check-mode").prop("checked", true);
    } else{
      $(".check-mode").prop("checked", false);
      $('body').removeClass("white-mode");
    } 
  }
  $('.check-mode').on("change", function(){
    if($(this).is(":checked")){     
      setTimeout(function(){        
        localStorage.setItem('switchColor', "switchOn");  
        $('body').addClass("white-mode"); 
        $(".check-mode").prop("checked", true);
      }, 500);
    }else{
      localStorage.removeItem('switchColor');
      $(".check-mode").prop("checked", false);
      $('body').removeClass("white-mode");
    }
  });  

  var switchCursor = localStorage.getItem('switchCursor');
  checkCursor();
  function checkCursor(){
    if(switchCursor == "cursorOn"){
      $('body').addClass("default-cursor"); 
      $(".check-cursor").prop("checked", true);
    } else{
      $(".check-cursor").prop("checked", false);
      $('body').removeClass("default-curso");
    } 
  }
  
  $('.check-cursor').on("change", function(){
    if($(this).is(":checked")){
      localStorage.setItem('switchCursor', "cursorOn");  
      $('body').addClass("default-cursor"); 
      $(".check-cursor").prop("checked", true);
    } else{
      $('body').removeClass("default-cursor");
      localStorage.removeItem('switchCursor');
      $(".check-cursor").prop("checked", false);
    }
  });

  /***** Tooltip *****/
  $('[data-toggle="tooltip"]').tooltip();  

  $('.albums-popups').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery:{
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1] 
    },
    callbacks:{
      elementParse: function(item){
      if(item.el[0].className == 'video'){
        item.type = 'iframe';
      } else{
        item.type = 'image';
      }
      }
    },
  });
  setTimeout(function(){
    if ($("body").find("#selection_bubble").length == 1){
      $("#trackContainer").css("margin","0");
    }
  }, 100);

  /***** Back to top *****/
  var progressPath = document.querySelector('.progress-wrap path');
  var pathLength = progressPath.getTotalLength();
  progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
  progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
  progressPath.style.strokeDashoffset = pathLength;
  progressPath.getBoundingClientRect();
  progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';		
  var updateProgress = function () {
    var scroll = $(window).scrollTop();
    var height = $(document).height() - $(window).height();
    var progress = pathLength - (scroll * pathLength / height);
    progressPath.style.strokeDashoffset = progress;
  }
  updateProgress();
  $(window).scroll(updateProgress);	
  var offset = 50;
  var duration = 550;
  jQuery(window).on('scroll', function() {
    if (jQuery(this).scrollTop() > offset) {
      jQuery('.progress-wrap').addClass('active-progress');
    } else {
      jQuery('.progress-wrap').removeClass('active-progress');
    }
  });				
  jQuery('.progress-wrap').on('click', function(event) {
    event.preventDefault();
    jQuery('html, body').animate({scrollTop: 0}, duration);
    return false;
  })
	/***** Back to top End *****/
  $('#headerSearch').on("change", function(){
    var searchVal = $(this).val();
    if(searchVal.length > 2 ){
      $('.search-submit').on("click", function(e){
        window.location = "https://www.navines.com/?s="+searchVal;
      });
    }
  });

});



/*******onLoad Modal *****/
var neoModalTitles = [
  'Athletis - Online Shopping',
  'Koshertop - Online Shopping',
  'Neoboard - Chrome Extension', 
  'RamSport - Store Your Brands',
];
var neoModalClass = [
  "athletis-modal",
  "koshertop-modal",
  "neob-modal",
  "ram-modal",
 ];
var flashOffers = ['Hurry Up!','Huge Discount','Sale 50% OFF','Huge Discount'];
var neoModalTexts = [  
  'Athletis stores sell <span>orthopedic shoes, running shoes and sneakers that will suit different activities</span>. As well as accessories and sportswear for men, women and children.',
  'Koshertop is the web storefront for our family market. It sell <span>groceries, ready meals, Israeli wines</span>, and Dead sea Minerals Cosmetics.',
  'The first eCommerce seller extension instantly Translation Messages Templates Track Package Calculator and eBay Calculator in <span>same screen!</span>', 
  'Ram Sports The leading brand store is now also coming online! With a wide range of brands and huge promotions on a <span>variety of items!</span>.', 
];
var neoModalurl = [
  "https://www.athletis.co.il/",
  "https://koshertop.com/",
  "https://chrome.google.com/webstore/detail/neoboard-lite/hlfebbnjfhjgdaomhoeeeoobkmlnbini",
  "https://www.ramsport.co.il/",
];
var neoModalBtnText = [  
 "Buy Now!",
 "Buy Now!",
 "Download Now!",
 "Buy Now!",
];

var length = Math.floor(Math.random() * neoModalTexts.length);
$('<span>' + neoModalTitles[length] + '</span>').prependTo('#neoModalTitle');
$('#neoModalText').html(neoModalTexts[length]);
$('#flashOffer').html(flashOffers[length]);
$('#neoModalBtns').html('<a id href="'+ neoModalurl[length] +'" target="_blank" class="btn btn-primary">'+ neoModalBtnText[length] +'</a>');
$('#neoModalSize').addClass(neoModalClass[length]);
/*******onLoad Modal End *****/

//Pixi JS Noise filter
function NoiseFilter(){
var vertexShader = null;
var fragmentShader = [
  'precision highp float;',
'',
'varying vec2 vTextureCoord;',
'varying vec4 vColor;',
'',
'uniform float multiplier;',
'uniform sampler2D uSampler;',
'',
'float rand(vec2 co)',
'{',
'    return fract(sin(dot(co.xy, vec2(30, 50))) * (5000.0+(10000.0*multiplier)));',
'}',
'',
'void main()',
'{',
'    vec4 color = texture2D(uSampler, vTextureCoord);',
'',
'    float diff = (rand(vTextureCoord) - 0.895) ;',
'',
'    color.r += diff;',
'    color.g += diff;',
'    color.b += diff;',
'',
'    gl_FragColor = color;',
'}'
].join('\n');
var uniforms ={
  multiplier:{ type: '1f', value: 0.5 },
};

PIXI.AbstractFilter.call(this,
  vertexShader,
  fragmentShader,
  uniforms
);
} 
NoiseFilter.prototype = Object.create(PIXI.AbstractFilter.prototype); 
Object.defineProperties(NoiseFilter.prototype,{
  multiplier:{
    get: function (){
      return this.uniforms.multiplier.value;
    },
    set: function (value){
      this.uniforms.multiplier.value = value;
    }
  }
});  
$(function(){
  var bgMainCanvas = null,
  bgMainContext = null,
  bgRenderer = null,
  bgStage = null,
  bgBackground = null,
  bgFilter = null;

/*** Init ------ ***/
  function bgCanvasInit(){
  // Selectors
  var container = $('.global-noise');
  $('body').addClass('no-background');

  // Create renderer
  bgRenderer = PIXI.autoDetectRenderer(container.width(), container.height(),{transparent: true});
  bgRenderer.clearBeforeRender = true;

  //Add the canvas to the HTML document
  container.append(bgRenderer.view);

  // Create a container object called the `stage`
  bgStage = new PIXI.Container();

  // Create background
  bgBackground = new PIXI.Graphics();
  bgBackground.beginFill(0x070707, 0);
  bgBackground.drawRect(0, 0, container.width(), container.height());
  bgBackground.endFill();
  bgStage.addChild(bgBackground);

  // Noise filter
  bgFilter = new NoiseFilter();
  bgStage.filters = [bgFilter];

  // Move noise
  setInterval(function(){
    bgFilter.multiplier = Math.random();
  }, 50);
  // Render
  bgCanvasRender();
  }
  if (Modernizr.webgl)
    bgCanvasInit();

/*** Resize --------- ***/
  bgCanvasResize();
  function bgCanvasResize(){
  // Selectors
  var container = $('.global-noise'); 
  // Resize background
    bgBackground.width = container.width()+1;
    bgBackground.height = container.height(); 
    // Resize renderer
    bgRenderer.resize(container.width()+1, container.height());
  }

/*** Render -------------- ***/
  function bgCanvasRender(){
  bgRenderer.render(bgStage); 
  // rAF
  requestAnimationFrame(function(){
    bgCanvasRender()
  });
  }
});
//End Pixi JS Noise filter



//window.onload = function(){document.getElementById("preloader").style.display = "none";}

/************************************************************/
/* $(".hpost-title").on('mousedown', function() {
    mousehover = false;
    setRandomClass();
  });*/
  

  /****** onLoad modal ******/
  /*setTimeout(function(){
    var modalCount = sessionStorage.getItem('modalCount');
    if(modalCount == null || modalCount == undefined || modalCount < 2){      
      $('#neoModal').modal('show');
      $('#js-modal-holder').show();
      var currentValue = sessionStorage.getItem('modalCount') ? parseInt(sessionStorage.getItem('modalCount')) : 0;
      var newValue = currentValue  + 1;
      sessionStorage.setItem('modalCount', newValue);
    }else{
      $('#js-modal-holder').hide();
    }
  }, 7000); */
  
  /// Cursor Animation
  /***var cursor = $(".cursor"),
  follower = $(".cursor-follower");
  var posX = 0,
      posY = 0;
  var mouseX = 0,
      mouseY = 0;
  TweenMax.to({}, 0.016,{
    repeat: -1,
    onRepeat: function(){
      posX += (mouseX - posX) / 9;
      posY += (mouseY - posY) / 9;
      TweenMax.set(follower,{
        css:{
        left: posX - 18,
        top: posY - 18
        }
      });
      TweenMax.set(cursor,{
        css:{
        left: mouseX,
        top: mouseY
        }
      });
    }
  });
  $(document).on("mousemove", function(e){
    mouseX = e.clientX;
    mouseY = e.clientY;
  });
  // yellow circle
  $("a").on("mouseenter", function(){
      cursor.addClass("active");
      follower.addClass("active");
  });
  $("a").on("mouseleave", function(){
      cursor.removeClass("active");
      follower.removeClass("active");
  }); ***/
  // ****** End Cursor Animation ******/
  /***funSelectScroll();  
  function funSelectScroll(){
    $('.navbar-nav').mCustomScrollbar('destroy');
    setTimeout(function(){
      $('.navbar-nav').mCustomScrollbar({
        theme: "minimal-dark",
        axis: 'y',
        scrollbarPosition: 'inside',
        advanced:{
          updateOnContentResize: true,
        },
      });
    }, 8);
  }***/
  
  /***$("body").mCustomScrollbar({
    theme: "minimal-dark",
    mouseWheelPixels: 75,
    axis: 'y',
    scrollInertia: 275,
    scrollbarPosition: 'inside',
    advanced:{
      updateOnContentResize: true,
    },
  });***/
  
  /***
  $(document).ready(function(){
    var spinner = $('#loader');
    $("#toolForm").submit(function(event){         
    event.preventDefault(); 
    var barCode = $("#barCode").val();
    var country = $("#country").val();
    if(barCode == "" || country == "" ){
      $("#show-data").show().html("Tracking ID not found.");
    } else{
        spinner.show();       
        var actionFile = $(this).attr("action");         
        var formValues = $(this).serialize();        
        $.post(actionFile, formValues, function(data){ 
          var showData = $('#show-data'); 
          var showTitle = $('#data-title');  
          var showNoData = $('#no-data'); 
          var downloadIcon = $('.download-track')
          var json = JSON.parse(data);                      
          showData.empty();
          showTitle.empty(); 
          showNoData.empty(); 
            if(!json.TrackingDetails){
              spinner.hide();       
              $('#toolModal').modal('show');  
              downloadIcon.hide();    
              showNoData.html("Tracking ID not found.");
            }    
            if(json.TrackingDetails.length){ 
              spinner.hide();  
              downloadIcon.show();         
              $('#toolModal').modal('show');                  
              var isTital = true;
              json.TrackingDetails.map(function (item){
                if (isTital){
                  var text = item.trackingNumber;                  
                  var title = $('<span />').html(text);
                  showTitle.append(title); 
                  isTital = false;
                }
                var content = ''
                content = content + '<div class="col-md-6 modaltool-col"> <h5>Date</h5> <p>' + item.datetime + '</p></div>'
                content = content + '<div class="col-md-6 modaltool-col"> <h5>Carrier</h5> <p>' + item.post + '</p></div>'
                content = content + '<div class="col-md-6 modaltool-col"> <h5>Event/location</h5> <p>' + item.eventLocation + '</p></div>'
                content = content + '<div class="col-md-6 modaltool-col"> <h5>Additional</h5> <p>' + item.additional + '</p></div>'; 
                var list = $(' <div class="row modaltool-data" />').html(content);
                showData.append(list);                                      
              }); 
            }                   
        });       
      }  
    });
  });  
  ***/
  
  /***
  $(window).on("load",function(){ 
    class SmoothScroll{
      constructor(options){    
        this.endThreshold = 0.01;
        this.requestId = null;
        this.maxDepth = 10;
        this.viewHeight = 0;
        this.halfViewHeight = 0;
        this.maxDistance = 0;
        this.viewWidth = 0;
        this.halfViewWidth = 0;
        this.maxDistanceWidth = 0;
        this.scrollHeight = 0;
        this.endScroll = 0;
        this.returnCurrentScroll = 0;
        this.currentScroll = 0;
        this.scrollTransform = 0;
        this.horizontalScroll = 0;
        this.resizeRequest = 1;
        this.scrollRequest = 0;
        this.scrollItems = [];
        this.lastTime = -1;
        this.maxElapsedMS = 100;
        this.targetFPMS = 0.07;
        this.target = options.target;
        this.scrollEase = options.scrollEase != null ? options.scrollEase : 0.1;
        this.maxOffset = options.maxOffset != null ? options.maxOffset : 500;
        this.horizontalScrollWrapper = options.horizontalScrollWrapper;
        this.horizontalScrollTarget = options.horizontalScrollTarget;
        this._horziontalSetHeihgt();
      
      if ( $('body').hasClass('home')){
        this.rectHorStart = this.horizontalScrollWrapper.getBoundingClientRect();    
        this.horzItemStart ={
          top: this.rectHorStart.top,
          bottom: this.rectHorStart.bottom,
          height: this.rectHorStart.height
        }
      }
    
        window.addEventListener("resize", this._onResize);
        window.addEventListener("scroll", this._onScroll);
        this._update();
      }
     
      _horizonstalScrollRect = (event) =>{
        const horzintalRect = this.horizontalScrollTarget.getBoundingClientRect();
        return horzintalRect;
      }
    
      _lastScrollRect = (event) =>{
        const lastScrollRect = this.horizontalScrollTarget.lastElementChild.getBoundingClientRect();
        return lastScrollRect;
      }
    
      _horziontalSetHeihgt = (event) =>{
        let horScrHeight = 0;
        if (
          this.horizontalScrollTarget !== null &&
          this.horizontalScrollWrapper !== null
        ){
          const lastScrollRect = this._lastScrollRect();
          horScrHeight = this.horizontalScrollTarget.scrollWidth - lastScrollRect.width + this._horizonstalScrollRect().height;
          this.horizontalScrollWrapper.style.height = horScrHeight + "px";
        }
      }
    
      _onResize = (event) =>{
        this.resizeRequest++;
        if (!this.requestId){
          this.lastTime = performance.now();
          this.requestId = requestAnimationFrame(this._update);
        }
      };
    
      _onScroll = (event) =>{
        this.scrollRequest++;
        if (!this.requestId){
          this.lastTime = performance.now();
          this.requestId = requestAnimationFrame(this._update);
        }
      };
    
      _horizonstalScroll = (scrollY,dt) =>{
        if (this.horizontalScrollWrapper !== null){
          const rectHor = this.horizontalScrollWrapper.getBoundingClientRect();
          const lastScrollRect = this._lastScrollRect();
          const itemHor ={
            target: this.horizontalScrollTarget,
            targetRect: this._horizonstalScrollRect(),
            top: rectHor.top,
            bottom: rectHor.bottom + scrollY,
            topScroll: rectHor.top + scrollY,
            horizonstalMove: 0,
          };
          
          itemHor.horizonstalMove += this.currentScroll - this.horzItemStart.top;
          if(scrollY >= this.horzItemStart.top && scrollY <= this.horzItemStart.bottom - itemHor.targetRect.height){  
            itemHor.target.style.position = 'fixed';
            itemHor.target.style.transform = `translate3d(-${itemHor.horizonstalMove}px,0px,0px)`;
            if(lastScrollRect.x <= (lastScrollRect.width/2)){
              this.scrollTransform = this.horzItemStart.bottom - itemHor.targetRect.height;
              itemHor.target.style.top = this.horzItemStart.bottom - itemHor.targetRect.height+'px';
            }else{
              this.scrollTransform = this.horzItemStart.top;
              itemHor.target.style.top = this.horzItemStart.top+'px';
            }
          }
        }
      };
     
      _update = (currentTime = performance.now()) =>{
        let elapsedMS = currentTime - this.lastTime;
    
        if (elapsedMS > this.maxElapsedMS){
          elapsedMS = this.maxElapsedMS;
        }
    
        const deltaTime = elapsedMS * this.targetFPMS;
        const dt = 1 - Math.pow(1 - this.scrollEase, deltaTime);
        const resized = this.resizeRequest > 0;
        const scrollY = window.pageYOffset;
    
        if (resized){
          this._horziontalSetHeihgt();
          const height = this.target.clientHeight;
          document.body.style.height = height + "px";
          this.scrollHeight = height;
          this.viewHeight = window.innerHeight;
          this.halfViewHeight = this.viewHeight / 2;
          this.maxDistance = this.viewHeight * 2;
          this.resizeRequest = 0;
          this.viewWidth = window.innerWidth;
          this.halfViewWidth = this.viewWidth / 2;
          this.maxDistanceWidth = this.viewWidth * 2;
        }
    
        this.endScroll = scrollY;
        this.scrollTransform += (scrollY - this.scrollTransform) * dt;
        this.currentScroll += (scrollY - this.currentScroll) * dt;
        if (Math.abs(scrollY - this.currentScroll) < this.endThreshold || resized){
          this.currentScroll = scrollY;
          this.scrollRequest = 0;
        }
    
        if (
          Math.abs(scrollY - this.scrollTransform) < this.endThreshold ||
          resized
        ){
          this.scrollTransform = scrollY;
          this.scrollRequest = 0;
        }
    
        //horizontal scroll
        this._horizonstalScroll(this.currentScroll,dt);
        const scrollOrigin = this.currentScroll + this.viewHeight;
        this.target.style.transform = `translate3d(0px,-${this.scrollTransform}px,0px)`;
    
        //items
        for (let i = 0; i < this.scrollItems.length; i++){
          const item = this.scrollItems[i];
          const distance = scrollOrigin - item.top;
          const offsetRatio = distance / this.maxDistance;
          item.endOffset = Math.round(
            this.maxOffset * item.depthRatio * offsetRatio
          );
    
          if (Math.abs(item.endOffset - item.currentOffset < this.endThreshold)){
            item.currentOffset = item.endOffset;
          } else{
            item.currentOffset += (item.endOffset - item.currentOffset) * dt;
          }
          if(item.direction == "y"){
            item.target.style.transform = `translate3d(0px,${item.currentOffset}px,0px)`;
          }else if(item.direction == "x"){
            item.target.style.transform = `translate3d(${item.currentOffset}px,0px,0px)`;
          }
        }
        
        this.lastTime = currentTime;
        this.requestId = this.scrollRequest > 0 ? requestAnimationFrame(this._update) : null;
      };
    
      currentScrollReturn(){
        return this.currentScroll;
      }
    }
    
    document.documentElement.style.setProperty(
      "--scrollbar-size",
      getScrollbarSize() + "px"
    );
    
    var scroller = new SmoothScroll({
      target: document.querySelector(".page-wrapper"),
      scrollEase: 0.05,
      horizontalScrollWrapper: document.querySelector(".scroll-ontainer"),
      horizontalScrollTarget: document.querySelector(".scroll-area")
    });
    
    function getScrollbarSize(){
      var div = document.createElement("div");
      div.classList.add("scrollbar-test");
      document.body.appendChild(div);
      var size = div.offsetWidth - div.scrollWidth;
      document.body.removeChild(div);
      return size;
    }    
  });***/